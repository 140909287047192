import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { leaderboardKeys } from '../leaderboard.keys';
import { getLeaderboard } from '../leaderboard.service';
export const useLeaderboard = (subdomain = getSubdomain(), sprintId, initialData) => {
    return useInfiniteQuery({
        queryKey: leaderboardKeys.leaderboard(subdomain, sprintId),
        queryFn: ({ pageParam }) => getLeaderboard(subdomain, sprintId, pageParam),
        getNextPageParam: lastPage => lastPage.page + 1 <= lastPage.totalPages ? lastPage.page + 1 : undefined,
        refetchInterval: query => {
            if (query.state.data?.pages[0].status === 'resyncing')
                return 1000;
            return false;
        },
        initialData: initialData
            ? {
                pages: [initialData],
                pageParams: [1],
            }
            : undefined,
        placeholderData: keepPreviousData,
        initialPageParam: 1,
        retry: 1,
    });
};
