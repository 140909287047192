import { useMutation } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { sendCommunityInvite } from '../communities.service';
/**
 * Hook for sending invites by email a community
 * @example ```
 * const { mutate, isLoading } = useSendCommunityInvites('subdomain');
 * mutate([{ email: "contact@zealy.io", role: "editor" }]);
 * ```
 */
export const useSendCommunityInvites = (subdomain) => {
    const domain = subdomain || getSubdomain();
    return useMutation({
        mutationFn: (data) => sendCommunityInvite(domain, data),
    });
};
