import { useQuery } from '@tanstack/react-query';
import { getSubdomain, isValidSubdomain } from '@zealy/utils';
import { communityKeys } from '../communities.keys';
import { getCommunity } from '../communities.service';
/**
 * Queries a community by its subdomain
 * @param subdomain The subdomain of the community to query, @default subdomain will be inferred from the URL
 * @param initialData The initial data to return
 * @example const { data, isLoading } = useCommunity('my-community');
 */
export const useCommunity = (subdomain, initialData, enabled = true) => {
    const domain = subdomain || getSubdomain();
    return useQuery({
        queryKey: communityKeys.community(domain),
        queryFn: async () => getCommunity(domain),
        initialData,
        enabled: isValidSubdomain(domain) && enabled,
        retry: 1,
    });
};
