import { useQuery } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { listPromotionBooking } from '../subscription.service';
import { subscriptionsKeys } from '../subscriptions.keys';
export const usePromotionBookings = ({ subdomain = getSubdomain(), query, ...options } = {}) => {
    return useQuery({
        ...options,
        queryKey: subscriptionsKeys.listPromotionBooking(subdomain),
        queryFn: () => listPromotionBooking({ params: { subdomain }, query }),
    });
};
