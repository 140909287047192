import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { usersKeys } from '../users.keys';
import { deleteUserAccount } from '../users.service';
/**
 * Deletes a user account from the authenticated user
 */
export const useDeleteUserAccount = () => {
    const subdomain = getSubdomain();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: deleteUserAccount,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: usersKeys.user('me', subdomain),
            });
        },
    });
};
