export const analyticsKeys = {
    membersOverTime: (subdomain, startDate, endDate, interval) => [
        'members-over-time',
        subdomain,
        startDate.toISOString(),
        endDate.toISOString(),
        interval,
    ],
    newMembers: (subdomain, startDate, endDate, interval) => [
        'new-members',
        subdomain,
        startDate.toISOString(),
        endDate.toISOString(),
        interval,
    ],
    twitterFollowers: (subdomain, startDate, endDate, interval) => [
        'twitter-followers',
        subdomain,
        startDate.toISOString(),
        endDate.toISOString(),
        interval,
    ],
    discordMembers: (subdomain, startDate, endDate, interval) => [
        'discord-members',
        subdomain,
        startDate.toISOString(),
        endDate.toISOString(),
        interval,
    ],
    mostActiveUsers: (subdomain, startDate, endDate, interval) => [
        'most-active-users',
        subdomain,
        startDate.toISOString(),
        endDate.toISOString(),
        interval,
    ],
    topCountries: (subdomain, startDate, endDate) => [
        'top-countries',
        subdomain,
        startDate.toISOString(),
        endDate.toISOString(),
    ],
    topCommunities: (subdomain, startDate, endDate) => [
        'top-communities',
        subdomain,
        startDate.toISOString(),
        endDate.toISOString(),
    ],
    topQuests: (subdomain, startDate, endDate) => [
        'top-quests',
        subdomain,
        startDate.toISOString(),
        endDate.toISOString(),
    ],
    topReviewers: (subdomain, startDate, endDate) => [
        'top-reviewsers',
        subdomain,
        startDate.toISOString(),
        endDate.toISOString(),
    ],
    claimedQuests: (subdomain, status, startDate, endDate, interval) => [
        'claimed-quests',
        subdomain,
        status,
        startDate.toISOString(),
        endDate.toISOString(),
        interval,
    ],
    claimedQuestsStatusCount: (subdomain, startDate, endDate) => [
        'claimed-quests-status-count',
        subdomain,
        startDate.toISOString(),
        endDate.toISOString(),
    ],
    topReferrals: (subdomain, startDate, endDate) => [
        'top-referrals',
        subdomain,
        startDate.toISOString(),
        endDate.toISOString(),
    ],
};
