import { useQuery } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { leaderboardKeys } from '../leaderboard.keys';
import { getSprints } from '../leaderboard.service';
/**
 * Fetches the current sprints for a community
 */
export function useSprint(id) {
    const subdomain = getSubdomain();
    return useQuery({
        queryKey: leaderboardKeys.sprints(subdomain, id ?? ''),
        queryFn: async () => {
            const result = await getSprints(subdomain);
            return result.find(sprint => sprint.id === id);
        },
        enabled: subdomain !== 'root' && !!id && id !== 'new',
        staleTime: 30000,
    });
}
