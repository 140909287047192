import { extractErrorMessage } from '@zealy/utils';
import { apiV2 } from '../services';
export const getCommunitySubscription = async (subdomain) => {
    const res = await apiV2.subscription.get({
        params: { subdomain },
        cache: 'no-store',
    });
    if (res.status === 200) {
        return res.body;
    }
    else {
        // handle error here
        throw new Error('getCommunitySubscription error');
    }
};
export const getCommunitySubscriptionStatus = async (subdomain) => {
    const res = await apiV2.subscription.getStatus({
        params: { subdomain },
    });
    if (res.status === 200) {
        return res.body;
    }
    else {
        // handle error here
        throw new Error('getCommunitySubscriptionStatus error');
    }
};
export const getPricingTable = async (subdomain) => {
    const res = await apiV2.subscription.getPricingTable({
        params: { subdomain },
        cache: 'no-store',
    });
    if (res.status === 200) {
        return res.body.data;
    }
    else {
        // handle error here
        throw new Error(`getPricingTable error status=${res.status}, message=${extractErrorMessage(res.body)}`);
    }
};
export const getBillingPortalUrl = async (subdomain) => {
    const res = await apiV2.subscription.getBillingPortalUrl({
        params: { subdomain },
    });
    if (res.status === 200) {
        return res.body.url;
    }
    else {
        // handle error here
        throw new Error('getBillingPortalUrl error');
    }
};
export const createCheckoutSession = async (subdomain, usagePriceId, basePriceId, referralId) => {
    const res = await apiV2.subscription.createCheckoutSessionUrl({
        params: { subdomain },
        body: {
            usagePriceId,
            basePriceId,
            referralId,
        },
    });
    if (res.status === 200) {
        return res.body;
    }
    else {
        // handle error here
        throw new Error('createCheckoutSession error');
    }
};
export const updateSubscription = async (subdomain, body) => {
    const res = await apiV2.subscription.update({
        params: { subdomain },
        body,
    });
    if (res.status === 200) {
        return res.body;
    }
    else if (res.status === 422) {
        throw new Error('Card declined');
    }
    else {
        throw new Error('Could not update subscription');
    }
};
export const resumeSubscription = async (subdomain) => {
    const res = await apiV2.subscription.resume({
        params: { subdomain },
    });
    if (res.status !== 200) {
        // handle error here
        throw new Error('resumeSubscription error');
    }
};
export const cancelPlan = async (subdomain, cancellationReason) => {
    const res = await apiV2.subscription.cancel({
        params: { subdomain },
        body: cancellationReason,
    });
    if (res.status !== 200) {
        // handle error here
        throw new Error('cancelPlan error');
    }
};
export const listPromotionBooking = async (options) => {
    const res = await apiV2.promotionBooking.listPromotionBooking(options);
    if (res.status === 200) {
        return res.body;
    }
    else {
        // handle error here
        throw new Error('listPromotionBooking error');
    }
};
export const updatePromotionBooking = async (subdomain, body) => {
    const res = await apiV2.promotionBooking.createPromotionBooking({
        params: { subdomain },
        body,
    });
    if (res.status === 201 || res.status === 200) {
        return true;
    }
    else {
        // handle error here
        throw new Error('updatePromotionBooking error');
    }
};
