import { useQuery } from '@tanstack/react-query';
import { getMe } from '../../services/utils';
import { isUserConnected } from '../../users';
import { apiKeyKeys } from '../apiKeys.keys';
import { getApiKeys } from '../apiKeys.service';
export const useApiKeys = (userId = getMe(), initialData) => {
    return useQuery({
        queryKey: apiKeyKeys.keys(userId),
        queryFn: async () => getApiKeys(userId ?? ''),
        initialData,
        enabled: isUserConnected(),
    });
};
