import { AuthError, authErrorNames, extractErrorMessage } from '@zealy/utils';
import { apiV2 } from '../services';
export const updateEmail = async (userId, email, otp) => {
    const { status, body } = await apiV2.user.updateUserEmail({
        params: {
            id: userId,
        },
        body: {
            email,
            otp,
        },
    });
    if (status !== 200) {
        throw new AuthError({ message: extractErrorMessage(body), code: status });
    }
    return true;
};
export const sendOtp = async (data) => {
    const { status, body } = await apiV2.auth.sendOtp({
        body: data,
    });
    if (status !== 200) {
        throw new AuthError({ message: extractErrorMessage(body), code: status });
    }
    return true;
};
export const otpLogin = async (data) => {
    const { status, body } = await apiV2.auth.otpLogin({
        body: data,
    });
    if (status !== 200 && status !== 404) {
        throw new Error(extractErrorMessage(body));
    }
    return { status };
};
export const generateNonce = async () => {
    const res = await apiV2.auth.generateNonce();
    if (res.status !== 200) {
        throw new Error(`Failed to generate nonce: status=${res.status}, message=${extractErrorMessage(res.body)}`);
    }
    return res.body;
};
export const generateTonPayload = async () => {
    const res = await apiV2.auth.generateTonPayload();
    if (res.status !== 200) {
        throw new Error('Failed to generate payload');
    }
    return res.body;
};
export const createWalletSession = async (address) => {
    const res = await apiV2.auth.createWalletSession({
        body: {
            address,
        },
    });
    if (res.status !== 200) {
        throw new Error(`Failed to create nonce session: status=${res.status}, message=${extractErrorMessage(res.body)}`);
    }
    return res.body;
};
export const walletLogin = async (signature, sessionId, reCaptchaToken, invitationId) => {
    const { status, body } = await apiV2.auth.walletLogin({
        body: {
            sessionId,
            signature,
            invitationId,
            reCaptchaToken,
        },
    });
    if (status !== 200) {
        const errorMessage = extractErrorMessage(body);
        if (errorMessage && authErrorNames.includes(errorMessage)) {
            throw new AuthError({
                code: status,
                name: errorMessage,
                message: errorMessage,
                data: body && typeof body === 'object' && 'context' in body
                    ? body.context
                    : undefined,
            });
        }
        throw new Error(errorMessage);
    }
    return true;
};
export const verifySignedMessageForNetwork = async (signature, message, network) => {
    const { status, body } = await apiV2.auth.verifySignature({
        body: {
            signature,
            network,
            message: message,
        },
    });
    if (status !== 200) {
        const authErrorBody = body;
        if (authErrorBody.code && authErrorBody.message) {
            throw new AuthError({ message: authErrorBody.message, code: authErrorBody.code });
        }
        throw new Error('Failed to verify signature');
    }
    return true;
};
export const verifyTonProof = async (proof) => {
    const { status, body } = await apiV2.auth.verifyTonProof({
        body: proof,
    });
    if (status !== 200) {
        throw new Error('Failed to verify proof');
    }
    return body;
};
export const getZealyConnectStatus = async ({ questId, subdomain, }) => {
    const { status, body } = await apiV2.quest.getZealyConnectStatus({
        params: {
            subdomain,
            questId,
        },
    });
    if (status !== 200) {
        throw new Error('Failed to get zealy connect status');
    }
    return body;
};
export const generateRedirectUrlForApiQuestTask = async ({ questId, taskId, subdomain, }) => {
    const { status, body } = await apiV2.quest.generateRedirectUrlForApiQuestTask({
        params: {
            subdomain,
            questId,
            taskId,
        },
    });
    if (status !== 200) {
        throw new Error('Failed to generate redirect url');
    }
    return body;
};
