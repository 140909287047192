import { useQuery } from '@tanstack/react-query';
import { usersKeys } from '../users.keys';
import { getUserByField } from '../users.service';
export const useUserByField = (query, options) => {
    return useQuery({
        ...options,
        queryKey: usersKeys.byField(query.field, query.value),
        queryFn: () => getUserByField(query),
        enabled: !!query.value && !!query.field,
        retry: false,
    });
};
