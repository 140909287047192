import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { questKeys } from '../quests.keys';
import { createQuest } from '../quests.service';
/**
 * Hook for creating a quest
 * @example ```
 * const { mutate, isLoading } = useCreateQuest();
 * ```
 */
export const useCreateQuest = (subdomain = getSubdomain()) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (data) => createQuest({ subdomain, data }),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: questKeys.board(subdomain) });
        },
    });
};
