import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { updateSubscription } from '../subscription.service';
import { subscriptionsKeys } from '../subscriptions.keys';
/**
 * Mutation hook for changing your subscription plan
 */
export function useChangePlan() {
    const domain = getSubdomain();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ usagePriceId, basePriceId, isUpgrade, }) => updateSubscription(domain, {
            newUsagePriceId: usagePriceId,
            newBasePriceId: basePriceId,
            isUpgrade,
        }),
        onSuccess: (response, { isUpgrade, billingInterval }) => {
            if (isUpgrade) {
                queryClient.setQueryData(subscriptionsKeys.pricingTable(domain), (data) => {
                    const pricingTable = data;
                    return pricingTable.map(plan => ({
                        ...plan,
                        isCurrentPlan: plan.planId === response.planId,
                    }));
                });
            }
            queryClient.setQueryData(subscriptionsKeys.subscription(domain), (data) => {
                const subscription = data;
                return {
                    ...subscription,
                    trialPeriodEndAt: null,
                    isCancelled: false,
                    ...(!isUpgrade && {
                        downgradePlanId: response.planId,
                        downgradePlanInterval: billingInterval,
                    }),
                    ...(isUpgrade && {
                        billingPeriod: billingInterval,
                        downgradePlanId: null,
                        downgradePlanInterval: null,
                    }),
                };
            });
        },
    });
}
