import { apiV2 } from '../services/apiV2';
export const getMembersOverTime = async (subdomain, startDate, endDate, interval) => {
    const response = await apiV2.analytics.getMembers({
        params: { subdomain },
        query: { startDate: startDate.toISOString(), endDate: endDate.toISOString(), interval },
    });
    if (response.status !== 200) {
        throw new Error('Cannot get members over time.');
    }
    return response.body;
};
export const getNewMembers = async (subdomain, startDate, endDate, interval) => {
    const response = await apiV2.analytics.getNewMembers({
        params: { subdomain },
        query: { startDate: startDate.toISOString(), endDate: endDate.toISOString(), interval },
    });
    if (response.status !== 200) {
        throw new Error('Cannot get new members.');
    }
    return response.body;
};
export const getDiscordMembers = async (subdomain, startDate, endDate, interval) => {
    const response = await apiV2.analytics.getDiscordMembers({
        params: { subdomain },
        query: { startDate: startDate.toISOString(), endDate: endDate.toISOString(), interval },
    });
    if (response.status !== 200) {
        throw new Error('Cannot get new members.');
    }
    return response.body;
};
export const getTwitterFollowers = async (subdomain, startDate, endDate, interval) => {
    const response = await apiV2.analytics.getTwitterFollowers({
        params: { subdomain },
        query: { startDate: startDate.toISOString(), endDate: endDate.toISOString(), interval },
    });
    if (response.status !== 200) {
        throw new Error('Cannot get new members.');
    }
    return response.body;
};
export const getActiveUsers = async (subdomain, startDate, endDate, interval) => {
    const response = await apiV2.analytics.getActiveUsers({
        params: { subdomain },
        query: { startDate: startDate.toISOString(), endDate: endDate.toISOString(), interval },
    });
    if (response.status !== 200) {
        throw new Error('Cannot get members over time.');
    }
    return response.body;
};
export const getClaimedQuestsStatusCount = async (subdomain, startDate, endDate) => {
    const response = await apiV2.analytics.getClaimedQuestsStatusCount({
        params: { subdomain },
        query: { startDate: startDate.toISOString(), endDate: endDate.toISOString() },
    });
    if (response.status !== 200) {
        throw new Error('Cannot get claimed quests by status and count.');
    }
    return response.body;
};
export const getClaimedQuests = async (subdomain, status, startDate, endDate, interval) => {
    const response = await apiV2.analytics.getClaimedQuests({
        params: { subdomain },
        query: {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
            interval,
            status,
        },
    });
    if (response.status !== 200) {
        throw new Error('Cannot get claimed quests.');
    }
    return response.body;
};
export const getTopReferrals = async (subdomain, startDate, endDate) => {
    const response = await apiV2.analytics.getReferralUrlCount({
        params: { subdomain },
        query: { startDate: startDate.toISOString(), endDate: endDate.toISOString() },
    });
    if (response.status !== 200) {
        throw new Error('Cannot get top referrals.');
    }
    return response.body;
};
export const getTopCountries = async (subdomain, startDate, endDate) => {
    const response = await apiV2.analytics.getTopCountries({
        params: { subdomain },
        query: { startDate: startDate.toISOString(), endDate: endDate.toISOString() },
    });
    if (response.status !== 200) {
        throw new Error('Cannot get top countries.');
    }
    return response.body;
};
export const getTopCommunities = async (subdomain, startDate, endDate) => {
    const response = await apiV2.analytics.getTopCommunities({
        params: { subdomain },
        query: { startDate: startDate.toISOString(), endDate: endDate.toISOString() },
    });
    if (response.status !== 200) {
        throw new Error('Cannot get top communities.');
    }
    return response.body;
};
export const getTopQuests = async (subdomain, startDate, endDate) => {
    const response = await apiV2.analytics.getTopQuests({
        params: { subdomain },
        query: { startDate: startDate.toISOString(), endDate: endDate.toISOString() },
    });
    if (response.status !== 200) {
        throw new Error('Cannot get top quests.');
    }
    return response.body;
};
export const getTopReviewers = async (subdomain, startDate, endDate) => {
    const response = await apiV2.analytics.getTopReviewers({
        params: { subdomain },
        query: { startDate: startDate.toISOString(), endDate: endDate.toISOString() },
    });
    if (response.status !== 200) {
        throw new Error('Cannot get top reviewers.');
    }
    return response.body;
};
