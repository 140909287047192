import { useQuery } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { communityKeys } from '../communities.keys';
import { getCommunityWallet } from '../communities.service';
/**
 * Gets the wallet address of a community.
 * @example ```
 * const { data, isLoading } = useNFTCost('my-community');
 * ```
 */
export const useCommunityWallet = (subdomain = getSubdomain()) => {
    return useQuery({
        queryKey: communityKeys.wallet(subdomain),
        queryFn: () => getCommunityWallet(subdomain),
    });
};
