import { useMutation, useQueryClient } from '@tanstack/react-query';
import { usersKeys } from '../../users';
import { sendOtp } from '../auth.service';
export const useSendOtp = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: sendOtp,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: usersKeys.user('me') });
        },
    });
};
