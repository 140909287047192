import { useMutation, useQueryClient } from '@tanstack/react-query';
import { communityKeys } from '../communities.keys';
import { createCommunity } from '../communities.service';
/**
 * Hook for creating a community
 * @example ```
 * const { mutate, isLoading } = useCreateCommunity();
 * mutate({ name: 'My Community', blockchain: 'ethereum', description: 'My Community Description', image: File });
 * ```
 */
export const useCreateCommunity = (utmParams) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (data) => createCommunity(data, utmParams),
        onSuccess: data => {
            queryClient.setQueryData(communityKeys.community(data.subdomain), data);
        },
    });
};
