export const webhooksKeys = {
    types: (id) => ['api-keys', id],
    list: (subdomain) => ['webhook', subdomain],
    item: (subdomain, webhookId) => ['webhook', subdomain, webhookId],
    events: (subdomain, webhookId, filters) => [
        'webhook:events',
        subdomain,
        webhookId,
        filters,
    ],
};
