import { useQuery } from '@tanstack/react-query';
import { getOpenGraphMetadata } from '../opengraph.service';
const URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,8}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
export const useOpenGraph = (url) => {
    return useQuery({
        queryFn: () => getOpenGraphMetadata(url),
        queryKey: ['ogInfo', url],
        enabled: !!url.match(URL_REGEX),
    });
};
