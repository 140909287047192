import { useQuery } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { reviewsKeys } from '../reviews.keys';
import { getReviewEvents } from '../reviews.service';
export const useReviewEvents = (id, subdomain = getSubdomain(), initialData) => {
    return useQuery({
        queryKey: reviewsKeys.events(subdomain, id),
        queryFn: async () => getReviewEvents({
            params: { subdomain, id },
        }),
        initialData,
        enabled: !!id,
    });
};
