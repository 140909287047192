export const getMonitors = async () => {
    const response = await fetch('https://uptime.betterstack.com/api/v2/monitors?per_page=20&page=1', {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${process.env.BETTERSTACK_API_TOKEN}`,
        },
    });
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data.data;
};
