import { useQuery } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { usersKeys } from '../users.keys';
import { getCommunityMember, getUser } from '../users.service';
/**
 * Will return the general user data and data relevant for community if
 * subdomain is defined or possible to extract from the location path
 * @param id of user to get data from
 * @param subdomain of community to get user data from
 * @returns User object with role set if subdomain is defined
 */
export const useUser = (id, subdomain, initialData) => {
    const fetchData = async (subdomain) => {
        if (!id)
            return undefined;
        if (subdomain && subdomain !== 'root' && subdomain !== '_') {
            // Fetch community member data
            return getCommunityMember(id, subdomain);
        }
        else {
            // Fetch general user data
            return getUser(id);
        }
    };
    const communityDomain = subdomain ?? getSubdomain();
    return useQuery({
        queryKey: usersKeys.user(id, communityDomain),
        queryFn: () => fetchData(communityDomain),
        initialData,
        enabled: !!id,
    });
};
