import { api } from '../services/api';
export const getMembers = (subdomain, { query = {
    sortBy: 'role',
    orderBy: 'desc',
    role: ['admin', 'editor', 'reviewer'],
}, ...options } = {}) => api.get(`/communities/${subdomain}/members`, {
    query,
    ...options,
});
export const updateRole = ({ subdomain, userId, role, }) => api.post(`/communities/${subdomain}/members/change-role`, {
    body: JSON.stringify({ role, memberId: userId }),
});
export const deleteMember = ({ subdomain, userId }) => api.delete(`/communities/${subdomain}/members/${userId}`);
