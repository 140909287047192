import { useQuery } from '@tanstack/react-query';
import { discordUrlValidation } from '@zealy/utils';
import { questKeys } from '../quests.keys';
import { getDiscordMetadata } from '../quests.service';
export const useDiscordMetadata = (inviteLink, options) => {
    return useQuery({
        ...options,
        queryKey: questKeys.metadata('discord', inviteLink),
        queryFn: () => getDiscordMetadata(inviteLink),
        enabled: !!inviteLink && discordUrlValidation(inviteLink),
        retry: false,
    });
};
