import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { moduleKeys } from '../modules.keys';
import { deleteModule } from '../modules.service';
/**
 * Hook for deleting a module
 * @example ```
 * const { mutate, isLoading } = useDeleteModule();
 * ```
 */
export const useDeleteModule = (subdomain = getSubdomain()) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (moduleId) => deleteModule({ subdomain, moduleId }),
        onSuccess: data => {
            queryClient.removeQueries({ queryKey: moduleKeys.modules(subdomain, data.id) });
        },
    });
};
