import { useQuery } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { partnershipKeys } from '../partnership.keys';
import { listPartnerships } from '../partnership.service';
export const useListPartnerships = ({ subdomain = getSubdomain(), query, ...options }) => {
    return useQuery({
        ...options,
        queryKey: partnershipKeys.listPartnerships(subdomain, query.status, query.status === 'pending' ? query.position : undefined),
        queryFn: () => listPartnerships(subdomain, query),
    });
};
