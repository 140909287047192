import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { partnershipKeys } from '../partnership.keys';
import { updatePartnershipStatus } from '../partnership.service';
export const useUpdatePartnershipStatus = (partnershipId, subdomain = getSubdomain()) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ status }) => updatePartnershipStatus(partnershipId, subdomain, status),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: partnershipKeys.genericKey(subdomain),
            });
            queryClient.invalidateQueries({
                queryKey: partnershipKeys.searchPartnershipCommunities(subdomain, ''),
            });
        },
    });
};
