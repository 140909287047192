import { useQuery, useQueryClient } from '@tanstack/react-query';
import { webhooksKeys } from '../webhooks.keys';
import { getWebhooks } from '../webhooks.service';
export const useWebhook = ({ subdomain, webhookId }) => {
    const queryClient = useQueryClient();
    return useQuery({
        queryKey: webhooksKeys.item(subdomain, webhookId),
        queryFn: async () => {
            const cached = queryClient.getQueryData(webhooksKeys.list(subdomain));
            if (cached) {
                const item = cached.pages.flatMap(({ items }) => items)?.find(r => r.id === webhookId);
                if (item) {
                    return item;
                }
            }
            const { items } = await getWebhooks(subdomain);
            return items.find(({ id }) => id === webhookId);
        },
        staleTime: 1000,
    });
};
