import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { questKeys } from '../quests.keys';
import { updateQuest } from '../quests.service';
/**
 * Hook for updating a quest
 * @example ```
 * const { mutate, isLoading } = useUpdateQuest();
 * ```
 */
export const useUpdateQuest = (questId, subdomain = getSubdomain()) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (data) => updateQuest({ subdomain, questId, data }),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: questKeys.board(subdomain) });
        },
    });
};
