import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { questKeys } from '../quests.keys';
import { getQuestBoard } from '../quests.service';
export const useQuestBoard = (filters, options) => {
    const subdomain = getSubdomain();
    const queryClient = useQueryClient();
    return useQuery({
        queryKey: questKeys.board(subdomain, filters),
        queryFn: async () => {
            const quests = await getQuestBoard(subdomain, filters);
            quests.forEach(quest => {
                queryClient.setQueryData(questKeys.module(subdomain, quest.id, filters), quest);
            });
            return quests;
        },
        staleTime: 300000,
        ...options,
    });
};
