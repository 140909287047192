import { useQuery } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { communityKeys } from '../communities.keys';
import { listCommunitySecrets } from '../communities.service';
export const useCommunitySecrets = ({ subdomain = getSubdomain(), ...options } = {}) => {
    return useQuery({
        ...options,
        queryKey: communityKeys.listCommunitySecrets(subdomain),
        queryFn: () => listCommunitySecrets(subdomain),
    });
};
