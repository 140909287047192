import { publicApiRoutes } from '@zealy/utils';
import { api } from '../services/api';
export const getApiKeys = (userId, options) => api.get(`/users/${userId}/api-keys`, options);
const getPayload = (writeAccess, name) => ({
    name,
    authorizedRoutes: writeAccess
        ? publicApiRoutes
        : publicApiRoutes.filter(({ method }) => method === 'GET'),
});
export const postApiKey = (userId, writeAccess, name) => api.post(`/users/${userId}/api-keys-v2`, {
    body: JSON.stringify(getPayload(writeAccess, name)),
});
export const deleteApiKey = (userId, apiKeyId) => api.delete(`/users/${userId}/api-keys-v2/${apiKeyId}`);
