import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { usersKeys } from '../../users';
import { setRole } from '../devTools.service';
export const useSetRole = ({ userId = 'me', subdomain = getSubdomain() }) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (role) => setRole({ userId, subdomain }, role),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: usersKeys.user('me') });
        },
    });
};
