import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { getInvites, isUserConnected } from '../../users/users.service';
import { usersKeys } from '../users.keys';
/**
 * Gets an authenticated user's invites
 * @example ```
 * const { data, isLoading } = useInvites();
 * console.log(data)
 * ```
 */
export const useInvites = ({ subdomain = getSubdomain(), userId, invitesQuestId, status = 'active', }, initialData) => {
    return useInfiniteQuery({
        queryKey: usersKeys.invites(userId ?? '', subdomain, status),
        queryFn: ({ pageParam }) => getInvites({ pageParam, invitesQuestId, userId: userId ?? '', subdomain, status }),
        getNextPageParam: lastPage => {
            if (lastPage.hasNextPage) {
                return lastPage.nextPage;
            }
            return undefined;
        },
        placeholderData: keepPreviousData,
        initialData: initialData
            ? {
                pages: [initialData],
                pageParams: [1],
            }
            : undefined,
        enabled: isUserConnected() && !!userId,
        retry: 3,
        initialPageParam: 1,
    });
};
