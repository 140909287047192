import { apiV2 } from '../services';
import { api } from '../services/api';
export const getLeaderboard = async (subdomain, sprintId, page = 1, _next) => {
    const res = await apiV2.leaderboard.get({
        params: { subdomain },
        query: {
            page,
            sprintId,
        },
        _next,
    });
    if (res.status === 200) {
        return res.body;
    }
    else {
        // handle error here
        throw new Error('getLeaderboard error');
    }
};
export const getSprints = async (subdomain, options) => {
    const res = await apiV2.leaderboard.listCommunitySprints({
        params: { subdomain },
        ...options,
    });
    if (res.status === 200) {
        return res.body.data;
    }
    else {
        // handle error here
        throw new Error('getSprints error');
    }
};
export const createSprint = async (subdomain, data) => {
    const res = await apiV2.leaderboardSprint.create({
        params: { subdomain },
        body: data,
    });
    if (res.status === 200) {
        return res.body;
    }
    else {
        // handle error here
        throw new Error('createSprint error');
    }
};
export const updateSprint = async (subdomain, sprintId, data) => {
    const res = await apiV2.leaderboardSprint.update({
        params: { subdomain },
        body: { ...data, id: sprintId },
    });
    if (res.status === 200) {
        return res.body;
    }
    else {
        // handle error here
        throw new Error('updateSprint error');
    }
};
export const exportLeaderboard = (subdomain, data) => api.post(`/communities/${subdomain}/exports`, {
    body: data,
});
export const deleteSprint = async (subdomain, sprintId) => {
    const res = await apiV2.leaderboardSprint.delete({
        params: { subdomain },
        query: {
            id: sprintId,
        },
    });
    if (res.status !== 200) {
        throw new Error('deleteSprint error');
    }
};
export const getRank = async (subdomain, userId, sprintId) => {
    const res = await apiV2.leaderboard.getRank({
        params: { subdomain, userId, sprintId },
    });
    if (res.status === 200) {
        return res.body;
    }
    else {
        // handle error here
        throw new Error('getRank error');
    }
};
export const getUpcomingAndRecentSprints = async () => {
    const res = await apiV2.leaderboardSprint.list();
    if (res.status === 200) {
        return res.body.data;
    }
    else {
        throw new Error('getUpcomingAndRecentSprints error');
    }
};
