import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { questKeys } from '../quests.keys';
import { getTaskResult } from '../quests.service';
/**
 * Queries task results
 * @param id The ID of the task to query
 * @param subdomain The subdomain of the community to query, @default subdomain will be inferred from the URL
 * @param initialData The initial data to return
 * @example const { data, isLoading } = useTaskResults('1', 'text', 'subdomain');
 */
export const useTaskResult = (questId, id, subdomain = getSubdomain()) => {
    return useInfiniteQuery({
        queryKey: questKeys.taskResults(subdomain, id),
        queryFn: ({ pageParam }) => getTaskResult(subdomain, questId, id, {
            query: {
                page: pageParam ?? 1,
            },
        }),
        getNextPageParam: ({ nextPage }) => nextPage,
        retry: 3,
        placeholderData: keepPreviousData,
        initialPageParam: 1,
    });
};
