import { extractErrorMessage } from '@zealy/utils';
import { api, apiV2 } from '../services';
import { getMe } from '../services/utils';
export const getCommunities = async (category = 'all', limit = 12, page, web2) => {
    if (!web2 && category !== 'featured') {
        return api.get('/communities', {
            params: {
                category,
                limit,
                page,
            },
        });
    }
    else {
        const res = await apiV2.community.list({
            query: {
                category: category,
            },
        });
        if (res.status === 200) {
            const communities = res.body.communities.map(({ totalTwitterFollowers, totalQuests, ...rest }) => ({
                ...rest,
                twitterFollowers: totalTwitterFollowers,
                quests: `${totalQuests}`,
            }));
            return {
                communities,
                totalCommunities: communities.length,
                totalPages: 1,
            };
        }
        else {
            // handle error here
            throw new Error('getCommunities fail');
        }
    }
};
export const getCommunity = async (subdomain, fetchOptions) => {
    if (!subdomain || subdomain === 'root' || subdomain === '_')
        throw new Error('Invalid subdomain');
    const res = await apiV2.community.get({ params: { subdomain }, fetchOptions });
    if (res.status === 200) {
        return res.body; // TODO fix this
    }
    // handle error here
    throw new Error(`getCommunity fail: status=${res.status}, message=${extractErrorMessage(res.body)}`);
};
export const createCommunity = async (data, utmParams) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
        if (value) {
            formData.append(key, value);
        }
    });
    const response = await api.post('/communities', {
        body: formData,
        params: utmParams,
    });
    return response;
};
export const updateCommunity = async (subdomain, data) => {
    const includesFile = Object.keys(data).some(key => key === 'image');
    if (includesFile) {
        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            if (value) {
                formData.append(key, value);
            }
        });
        return api.patch(`/communities/${subdomain}`, {
            body: formData,
        });
    }
    return api.patch(`/communities/${subdomain}`, {
        body: JSON.stringify(data),
    });
};
export const deleteCommunity = async (subdomain) => api.delete(`/communities/${subdomain}`);
export const resetLeaderboard = async (subdomain) => api.delete(`/communities/${subdomain}/leaderboard`);
export const getTwitterOAuthURI = async (subdomain) => api.get(`/communities/${subdomain}/oauth/twitter`);
export const getReferralLink = async (subdomain) => api.get(`/communities/${subdomain}/users/me/referral-link`);
export const verifyCommunityExternalAccounts = async (subdomain) => {
    const response = await api.get(`/communities/${subdomain}/oauth/verify`);
    return response;
};
export const unlinkCommunityAccount = async (subdomain, accountType) => {
    const response = await api.delete(`/communities/${subdomain}/oauth/${accountType}`);
    return response;
};
export const getCommunityInvite = async (subdomain, options) => {
    const response = await api.get(`/communities/${subdomain}/members/invitation-link`, {
        query: options,
    });
    return response;
};
export const sendCommunityInvite = async (subdomain, invites) => api.post(`/communities/${subdomain}/members/email-invites`, {
    body: JSON.stringify({ invites }),
});
export const getUserCommunities = async (userId, page = 0, limit = 30) => {
    const resolvedUserId = userId === 'me' ? getMe() : userId;
    const res = await apiV2.user.getUserCommunities({
        params: { id: resolvedUserId },
        query: { page, limit },
    });
    if (res.status === 200) {
        const { communities, total } = res.body;
        return {
            results: communities, // TODO fix this
            nextPage: page + 1,
            hasNextPage: page + 1 < Math.ceil(total / limit),
        };
    }
    // handle error here
    throw new Error(`getUserCommunities fail: status=${res.status}, message=${extractErrorMessage(res.body)}`);
};
export const searchUserCommunities = async (userId, search) => {
    if (!search?.trim())
        return [];
    const resolvedUserId = userId === 'me' ? getMe() : userId;
    const res = await apiV2.user.searchUserCommunities({
        params: { id: resolvedUserId },
        query: { search },
    });
    if (res.status === 200) {
        return res.body.communities; // TODO fix this
    }
    // handle error here
    throw new Error(`searchUserCommunities fail: status=${res.status}, message=${extractErrorMessage(res.body)}`);
};
export const getExploreCommunities = async ({ category = 'all', pageParam = 0, search, limit = 30, endpoint = '/communities', web2, }, config = {}) => {
    let communities;
    let totalCommunities;
    let hasNextPage;
    if (!web2 && category !== 'featured') {
        ({ communities, totalCommunities } = await api.get(endpoint, {
            ...config,
            params: {
                ...config?.params,
                category,
                search,
                page: pageParam,
                limit,
            },
        }));
        hasNextPage = pageParam + 1 < Math.ceil(totalCommunities / limit);
        return {
            results: communities,
            nextPage: pageParam + 1,
            hasNextPage,
            totalCommunities,
        };
    }
    else {
        const res = await apiV2.community.list({
            query: {
                category: category,
            },
        });
        if (res.status === 200) {
            return {
                results: res.body.communities.map(({ totalTwitterFollowers, totalQuests, ...rest }) => ({
                    ...rest,
                    twitterFollowers: totalTwitterFollowers,
                    quests: `${totalQuests}`,
                })),
                hasNextPage: false,
                totalCommunities: res.body.communities.length,
                nextPage: pageParam + 1,
            };
        }
        // handle error here
        throw new Error('getExploreCommunities fail');
    }
};
export const startFromTemplate = async (domain) => api.post(`/communities/${domain}/start-from-templates`);
export const leaveCommunity = async (subdomain, userId) => api.delete(`/communities/${subdomain}/members/${userId}`);
export const acceptInvitation = async (invitationId) => api.post('/users/me/accept-invitation', {
    body: { invitationId },
});
export const joinCommunity = async (subdomain, invitationId) => invitationId ? acceptInvitation(invitationId) : api.post(`/communities/${subdomain}/members`);
export const getInvitation = async (params) => {
    const response = await apiV2.invitation.get({ params });
    if (response.status === 400 || response.status === 404) {
        throw new Error(response.body.message);
    }
    if (response.status !== 200) {
        throw new Error('Failed to fetch');
    }
    return response.body;
};
export const setUserCommunities = async ({ communityIds }) => {
    if (!communityIds.length) {
        return;
    }
    return api.patch('/users/me/communities', {
        body: JSON.stringify({ communityIds }),
    });
};
/**
 * Reports a given community by subdomain and reason
 */
export function reportCommunity(subdomain, reason) {
    return api.post(`/communities/${subdomain}/report`, {
        body: {
            reason,
        },
    });
}
export const getCommunityDiscordRoles = async (subdomain) => api.get(`/communities/${subdomain}/plugins/discord/roles`);
export const getEstimatedNFTCost = async ({ subdomain, maxSupply, network, }) => {
    const { data } = await api.get(`/communities/${subdomain}/nft-rewards/estimated-amount-to-fund`, {
        params: {
            maxSupply,
            network,
        },
    });
    return Number(data.estimationAmount.fastest).toFixed(5);
};
export const getCommunityWallet = async (subdomain) => {
    const { data } = await api.get(`/communities/${subdomain}/nft-rewards/wallet`);
    return data.address;
};
export const uploadImage = async (file, subdomain) => {
    const formData = new FormData();
    formData.append('file', file);
    const { url } = await api.post('/files', {
        query: {
            subdomain,
        },
        body: formData,
    });
    return url;
};
export const getFailedDiscordRolesDelivery = async (subdomain) => {
    const { data } = await api.get(`/communities/${subdomain}/failed-discord-integration`);
    return data;
};
export const getCommunityOnboardingSteps = async (subdomain) => api.get(`/communities/${subdomain}/steps`);
export const claimNFT = async (subdomain, address, questId, nftId) => {
    const data = await api.post(`/communities/${subdomain}/quests/${questId}/nft-rewards/${nftId}/claim`, {
        body: JSON.stringify({ id: nftId, address, questId }),
    });
    return data;
};
export const listCommunitySecrets = async (subdomain) => {
    const res = await apiV2.communitySecret.listCommunitySecret({ params: { subdomain } });
    if (res.status !== 200) {
        throw new Error('listCommunitySecrets error');
    }
    return res.body;
};
export const createCommunitySecret = async (subdomain) => {
    const res = await apiV2.communitySecret.createCommunitySecret({ params: { subdomain } });
    if (res.status !== 201) {
        throw new Error('generateCommunitySecret error');
    }
    return res.body;
};
export const generateCommunityDetails = async (url) => {
    const res = await apiV2.community.generateDetails({ body: { url } });
    if (res.status !== 200) {
        throw new Error('generateCommunityDetails error');
    }
    return res.body;
};
