import { extractErrorMessage } from '@zealy/utils';
import { apiV2 } from '../services';
import { api } from '../services/api';
export const getNFTCollectionMetadataBatch = async (contractAddresses) => api.get(`/external/blockchain/polygon/contract/nft`, {
    query: {
        addresses: contractAddresses.join(','),
    },
});
export const getNFTCollectionMetadata = async (params, _next) => {
    const res = await apiV2.blockchain.getNFTCollectionMetadata({
        params: params,
        _next,
    });
    if (res.status === 200) {
        return res.body;
    }
    else {
        throw new Error(extractErrorMessage(res.body));
    }
};
export const getTokenContractMetadata = async (params, _next) => {
    const res = await apiV2.blockchain.getTokenContractMetadata({
        params: params,
        _next,
    });
    if (res.status === 200) {
        return res.body;
    }
    else {
        throw new Error(extractErrorMessage(res.body));
    }
};
