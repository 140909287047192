import { apiV2 } from '../services';
export const getNotifications = async (options) => {
    const res = await apiV2.notification.list({
        ...options,
    });
    if (res.status === 200) {
        return res.body;
    }
    else {
        // handle error here
        throw new Error('getNotifications failed');
    }
};
export const patchNotifications = async (options) => {
    const res = await apiV2.notification.setReadStatus({
        ...options,
    });
    if (res.status !== 200) {
        // handle error here
        throw new Error('getNotifications failed');
    }
};
