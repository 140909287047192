import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { reviewsKeys } from '../reviews.keys';
import { updateReviews } from '../reviews.service';
export const BATCH_REVIEW_LIMIT = 5;
export const useUpdateReviewStatus = (subdomain = getSubdomain(), featureFlag) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (body) => {
            return updateReviews(subdomain, {
                ...body,
                bonusXP: isNaN(body.bonusXP ?? 0) ? 0 : body.bonusXP,
            }, featureFlag);
        },
        onSuccess: (data, body) => {
            if (body.claimedQuestIds.length < BATCH_REVIEW_LIMIT) {
                body.claimedQuestIds.forEach(id => {
                    queryClient.invalidateQueries({ queryKey: reviewsKeys.claim(subdomain, id) });
                });
                // when removing the feature flag, only use data.reviewedClaimQuestIds.length
                const reviewedClaimsLength = featureFlag
                    ? data.reviewedClaimQuestIds.length
                    : data.reviewedQuests;
                queryClient.setQueryData(reviewsKeys.pendingCount(subdomain), (prev) => {
                    if (prev && prev < 100) {
                        return Math.max(prev - reviewedClaimsLength, 0);
                    }
                    return prev;
                });
            }
            else {
                queryClient.invalidateQueries({ queryKey: reviewsKeys.list(subdomain) });
            }
        },
    });
};
