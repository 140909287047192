import { useQuery } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { questKeys } from '../quests.keys';
import { getQuestRewards } from '../quests.service';
/**
 * Queries quest rewards
 * @param id The ID of the Quest to query
 * @param subdomain The subdomain of the community to query, @default subdomain will be inferred from the URL
 * @example const { data, isLoading } = useQuestResult('1', 'subdomain');
 */
export const useQuestRewards = (id, subdomain = getSubdomain(), { enabled } = { enabled: !!id }) => {
    return useQuery({
        queryKey: questKeys.questRewards(subdomain, id),
        queryFn: async () => getQuestRewards(subdomain, id),
        enabled,
    });
};
