import { useQuery } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { questKeys } from '../quests.keys';
import { generateMagicQuestSuggestions } from '../quests.service';
export const useMagicQuestSuggestions = ({ useCase, description, subdomain = getSubdomain(), ...options }) => {
    return useQuery({
        ...options,
        queryKey: questKeys.admin.aiSuggestions(subdomain, useCase, description),
        queryFn: async () => generateMagicQuestSuggestions(subdomain, { useCase, description }),
    });
};
