import { useQuery } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { usersKeys } from '../users.keys';
import { getInvitesCount, isUserConnected } from '../users.service';
export const useInvitesCount = ({ subdomain = getSubdomain(), userId, invitesQuestId, status = 'active', }) => {
    return useQuery({
        queryKey: usersKeys.invitesCount(userId ?? '', subdomain, status),
        queryFn: () => getInvitesCount({ invitesQuestId, userId: userId ?? '', subdomain, status }),
        enabled: isUserConnected() && !!userId,
        retry: 3,
    });
};
