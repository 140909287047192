import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { partnershipKeys } from '../partnership.keys';
import { createPartnership } from '../partnership.service';
export const useCreatePartnership = ({ subdomain = getSubdomain() }) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (data) => createPartnership(subdomain, data.message, data.targetedCommunityId, data.contact),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: partnershipKeys.genericKey(subdomain),
            });
            queryClient.invalidateQueries({
                queryKey: partnershipKeys.invalidateSearchPartnershipCommunities(subdomain),
            });
        },
    });
};
