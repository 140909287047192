import { useMutation, useQueryClient } from '@tanstack/react-query';
import { usersKeys } from '../users.keys';
import { createUserV2 } from '../users.service';
export const useCreateUserV2 = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: createUserV2,
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: usersKeys.user('me') });
        },
    });
};
