import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import { isUserConnected } from '../../users/users.service';
import { communityKeys } from '../communities.keys';
import { getUserCommunities, searchUserCommunities } from '../communities.service';
/**
 * Gets an authenticated user's communities
 * @example ```
 * const { data, isLoading } = useUserCommunities();
 * console.log(data)
 * ```
 */
export const useUserCommunities = (search, initialData) => {
    return useInfiniteQuery({
        queryKey: communityKeys.userCommunities(search),
        queryFn: async ({ pageParam }) => {
            if (search?.length) {
                const communities = await searchUserCommunities('me', search);
                return {
                    results: communities,
                    nextPage: 2,
                    hasNextPage: false,
                };
            }
            return getUserCommunities('me', pageParam);
        },
        getNextPageParam: lastPage => {
            if (lastPage.hasNextPage) {
                return lastPage.nextPage;
            }
            return undefined;
        },
        placeholderData: keepPreviousData,
        initialData: initialData
            ? {
                pages: [initialData],
                pageParams: [1],
            }
            : undefined,
        enabled: isUserConnected(),
        retry: 3,
        initialPageParam: 0,
    });
};
