import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { questKeys } from '../../quests/quests.keys';
import { moduleKeys } from '../modules.keys';
import { updateModule } from '../modules.service';
/**
 * Hook for updating a module
 * @example ```
 * const { mutate, isLoading } = useUpdateModule();
 * ```
 */
export const useUpdateModule = (moduleId, subdomain = getSubdomain()) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (data) => updateModule({ subdomain, moduleId, data }),
        onSuccess: data => {
            queryClient.setQueryData(moduleKeys.modules(subdomain, moduleId), data);
            queryClient.invalidateQueries({ queryKey: questKeys.board(subdomain) });
        },
    });
};
