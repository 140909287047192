import { useQuery } from '@tanstack/react-query';
import { questKeys } from '../quests.keys';
import { getTelegramMetadata } from '../quests.service';
export const useTelegramMetadata = (inviteLink, options) => {
    return useQuery({
        ...options,
        queryKey: questKeys.metadata('telegram', inviteLink),
        queryFn: () => getTelegramMetadata(inviteLink),
        enabled: !!inviteLink && inviteLink.startsWith('https://t.me/'),
        retry: false,
    });
};
