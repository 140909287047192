import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { leaderboardKeys } from '../leaderboard.keys';
import { createSprint } from '../leaderboard.service';
export const useCreateSprint = (subdomain = getSubdomain()) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (data) => createSprint(subdomain, data),
        onSuccess: data => {
            queryClient.setQueryData(leaderboardKeys.sprints(subdomain), data);
        },
    });
};
