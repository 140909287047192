import { query } from '@zealy/contracts/src/utils';
import { extractErrorMessage } from '@zealy/utils';
import { apiV2 } from '../services';
import { api } from '../services/api';
import { AppError } from '../types';
export const generateMagicQuest = async (subdomain, body) => {
    const res = await apiV2.magicQuest.generate({
        params: {
            subdomain,
        },
        body,
    });
    if (res.status === 200) {
        return res.body;
    }
    else {
        // handle error here
        throw new Error('getAdminQuestboardWithClaim failed');
    }
};
export const generateMagicQuestSuggestions = async (subdomain, body) => {
    const res = await apiV2.magicQuest.generateSuggestions({
        params: {
            subdomain,
        },
        body,
    });
    if (res.status === 200) {
        return res.body;
    }
    else {
        // handle error here
        throw new Error('generateMagicQuestSuggestions failed');
    }
};
export const getQuestboardModule = async (subdomain, moduleId, filters) => api.get(`/communities/${subdomain}/questboard/v2/${moduleId}`, {
    query: {
        filters,
    },
});
export const getQuestBoard = async (subdomain, filters) => api.get(`/communities/${subdomain}/questboard/v2`, {
    query: {
        filters,
    },
});
export const testClaimQuest = (subdomain, questId, tasks) => {
    return (taskValues) => api.post(`/communities/${subdomain}/quests/v2/${questId}/test-claim`, {
        body: {
            tasks,
            taskValues,
        },
    });
};
export const claimQuest = (subdomain, questId) => {
    return (params) => api.post(`/communities/${subdomain}/quests/v2/${questId}/claim`, {
        body: JSON.stringify(params),
        headers: {
            // TODO this is quickfix for rate limiting by community in CloudFront
            'X-Zealy-Subdomain': subdomain,
        },
    });
};
export const getQuest = (subdomain, questId, options) => api.get(`/communities/${subdomain}/quests/v2/${questId}`, {
    ...options,
});
export const getQuests = (subdomain) => api.get(`/communities/${subdomain}/quests`, {
    query: {
        version: 'v2',
    },
});
export const createQuest = async ({ subdomain, data }, generated) => {
    const res = await apiV2.quest.createQuest({
        params: { subdomain },
        body: data,
        query: generated ? { generated: 'true' } : undefined,
    });
    if (res.status === 200) {
        return res.body;
    }
    else {
        // handle error here
        throw new Error(`createQuest failed status=${res.status}, message=${extractErrorMessage(res.body)}`);
    }
};
export const updateQuest = ({ subdomain, questId, data }) => api.patch(`/communities/${subdomain}/quests/v2/${questId}`, {
    body: JSON.stringify(data),
});
export const updateQuestV2 = async ({ subdomain, questId, data }) => {
    const res = await apiV2.quest.updateQuest({
        params: { subdomain, questId },
        body: data,
    });
    if (res.status === 200) {
        return res.body;
    }
    if (res.status === 400) {
        throw new AppError(res.body.message, res.body.context);
    }
    else {
        throw new AppError('updateQuestV2 failed');
    }
};
export const updateQuests = ({ subdomain, questIds, data }) => api.patch(`/communities/${subdomain}/quests/`, {
    body: JSON.stringify({ questsIds: questIds, ...data }),
});
export const deleteQuest = ({ subdomain, questId }) => api.delete(`/communities/${subdomain}/quests/${questId}`);
export const getAdminQuest = (subdomain, questId, options) => api.get(`/communities/${subdomain}/quests/v2/${questId}/admin`, options);
export const getAdminQuestboard = (subdomain, options) => {
    return api.get(`/communities/${subdomain}/questboard/v2/admin`, {
        ...options,
        query: {
            ...query,
            filters: options?.query?.filters ? JSON.stringify(options?.query?.filters) : undefined,
        },
    });
};
export const getAdminQuestboardWithClaim = async (subdomain, startDate) => {
    const res = await apiV2.leaderboardSprint.listQuests({
        params: {
            subdomain,
        },
        query: {
            startDate,
        },
    });
    if (res.status === 200) {
        return res.body.data;
    }
    else {
        // handle error here
        throw new Error('getAdminQuestboardWithClaim failed');
    }
};
export const getTemplates = (subdomain, options) => api.get(`/communities/${subdomain}/templates/v2`, options);
export const listTemplateQuests = async (subdomain, options) => {
    const res = await apiV2.template.listTemplateQuests({
        ...options,
        params: {
            subdomain,
        },
    });
    if (res.status === 200) {
        return res.body.data;
    }
    else {
        // handle error here
        throw new Error('listTemplateQuests failed');
    }
};
export const listTemplateModules = async (subdomain, options) => {
    const res = await apiV2.template.listTemplateModules({
        ...options,
        params: {
            subdomain,
        },
    });
    if (res.status === 200) {
        return res.body.data;
    }
    else {
        // handle error here
        throw new Error('listTemplateModules failed');
    }
};
export const getTemplateQuest = async (params, options) => {
    const res = await apiV2.template.getTemplateQuest({
        ...options,
        params,
    });
    if (res.status === 200) {
        return res.body;
    }
    else {
        // handle error here
        throw new Error('getTemplateQuest failed');
    }
};
export const getTemplate = (subdomain, templateId, options) => api.get(`/communities/${subdomain}/templates/v2/${templateId}`, options);
export const reorderQuests = ({ subdomain, payload, }) => api.patch(`/communities/${subdomain}/quests/v2/reorder`, {
    body: payload,
});
export const getQuestResult = (subdomain, questId, options) => api.get(`/communities/${subdomain}/quests/v2/${questId}/result`, options);
export const getTaskResult = async (subdomain, questId, taskId, options) => {
    const data = await api.get(`/communities/${subdomain}/quests/v2/${questId}/tasks/${taskId}/result`, options);
    const receivedFullResult = data?.length >= Number(options?.query?.limit || 10);
    return {
        results: data,
        hasNextPage: receivedFullResult,
        nextPage: receivedFullResult ? Number(options?.query?.page || 1) + 1 : undefined,
    };
};
export const getTelegramMetadata = (inviteLink) => api.get(`/external/telegram/group/${encodeURIComponent(inviteLink)}`);
export const getDiscordMetadata = (inviteLink) => api.get(`/external/discord/${encodeURIComponent(inviteLink)}`);
export const getQuestRewards = async (subdomain, questId) => {
    const res = await apiV2.reward.get({
        params: { subdomain, questId },
    });
    if (res.status === 200) {
        return res.body.data;
    }
    else {
        // handle error here
        throw new Error('getQuestRewards failed');
    }
};
export const listTaskValidations = async (subdomain, questId, taskId, sortedBy) => {
    const res = await apiV2.quest.listTaskValidations({
        params: { subdomain, questId, taskId },
        query: {
            sortedBy,
        },
    });
    if (res.status === 200) {
        return res.body;
    }
    else {
        // handle error here
        throw new Error('listTaskValidations failed');
    }
};
export const updateTokenRewardTransaction = async ({ id, subdomain, data, }) => {
    const res = await apiV2.tokenReward.updateTokenRewardTransaction({
        params: { subdomain, tokenRewardId: id },
        body: data,
    });
    if (res.status === 200) {
        return res.body;
    }
    if (res.status === 400) {
        throw new AppError(res.body.message, res.body.context);
    }
    else {
        throw new AppError('updateTokenRewardTransaction failed');
    }
};
export const getQuestVoteFeed = async ({ subdomain, questId, rewardId, pagination, }) => {
    const res = await apiV2.quest.getQuestVoteFeed({
        params: { subdomain, questId, rewardId },
        query: { limit: pagination?.limit, cursor: pagination?.cursor },
    });
    if (res.status === 200) {
        return res.body;
    }
    else {
        throw new Error('getQuestVoteFeed failed');
    }
};
export const voteEvent = async (params, body) => {
    const res = await apiV2.quest.createVoteEvents({ params, body });
    if (res.status === 201) {
        return res.body;
    }
    throw new AppError('voteEvent failed');
};
export const listRecommendedQuests = async () => {
    const res = await apiV2.quest.listRecommendedQuests();
    if (res.status === 200) {
        return res.body;
    }
    throw new AppError('listRecommendedQuests failed');
};
export const getQuestVoteResults = async ({ subdomain, questId, rewardId, pagination, }) => {
    const res = await apiV2.quest.getQuestVoteResults({
        params: { subdomain, questId, rewardId },
        query: { limit: pagination?.limit, cursor: pagination?.cursor },
    });
    if (res.status === 200) {
        return res.body;
    }
    else {
        throw new Error('getQuestVoteResults failed');
    }
};
