import { useQuery } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { discordKeys } from '../discord.keys';
import { getFailedAutomations } from '../discord.service';
/**
 * Gets info about how many role rewards have failed to be set & why
 */
export const useFailedAutomations = (subdomain = getSubdomain(), options) => {
    return useQuery({
        ...options,
        queryKey: discordKeys.failedRoles(subdomain),
        queryFn: async () => getFailedAutomations(subdomain),
        enabled: !!subdomain && subdomain !== 'root' && options?.enabled !== false,
    });
};
