import { useQuery } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { crmKeys } from '../crm.keys';
import { getCRM } from '../crm.service';
export const useCRM = ({ subdomain = getSubdomain(), filters, ...options }) => {
    return useQuery({
        ...options,
        queryKey: crmKeys.crm(subdomain, filters),
        queryFn: () => getCRM({ path: { subdomain }, query: filters }),
    });
};
