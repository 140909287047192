import { useQuery } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { moduleKeys } from '../modules.keys';
import { getModules } from '../modules.service';
/**
 * Queries a community modules by its ID
 * @param subdomain The subdomain of the community to query, @default subdomain will be inferred from the URL
 * @param initialData The initial data to return
 * @example const { data, isLoading } = useModules('subdomain');
 */
export const useModules = (subdomain = getSubdomain(), initialData) => {
    return useQuery({
        queryKey: moduleKeys.modules(subdomain),
        queryFn: async () => getModules(subdomain),
        initialData,
        enabled: !!subdomain && subdomain !== 'root',
    });
};
