import { useQuery } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { crmKeys } from '../crm.keys';
import { getView } from '../crm.service';
export const useCRMView = ({ subdomain = getSubdomain(), ...options } = {}) => {
    return useQuery({
        ...options,
        queryKey: crmKeys.view(subdomain),
        queryFn: async () => getView({ path: { subdomain } }),
    });
};
