import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { cancelPlan } from '../subscription.service';
import { subscriptionsKeys } from '../subscriptions.keys';
/**
 * Mutation hook for canceling the current subscription
 */
export function useCancelPlan() {
    const domain = getSubdomain();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (reason) => cancelPlan(domain, reason),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: subscriptionsKeys.subscription(domain) });
            queryClient.invalidateQueries({ queryKey: subscriptionsKeys.pricingTable(domain) });
            queryClient.setQueryData(subscriptionsKeys.subscription(domain), (data) => {
                const subscription = data;
                return {
                    ...subscription,
                    trialPeriodEndAt: null,
                    isCancelled: true,
                };
            });
        },
    });
}
