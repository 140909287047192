import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getMe } from '../../services/utils';
import { npsKeys } from '../nps.keys';
import { postNPS } from '../nps.service';
export const useSubmitNPS = (userId = getMe()) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (body) => postNPS(userId ?? 'me', body),
        onSuccess: data => {
            queryClient.invalidateQueries({ queryKey: npsKeys.lastScore() });
        },
    });
};
