import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { reviewsKeys } from '../reviews.keys';
import { cancelReview } from '../reviews.service';
export const useCancelReview = (subdomain = getSubdomain(), featureFlag) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (ids) => cancelReview(subdomain, ids, featureFlag),
        onSuccess: (data, ids) => {
            // when removing the feature flag, only use data.reviewedClaimQuestIds
            const claimedQuestIds = featureFlag
                ? data.reviewedClaimQuestIds
                : data.map(({ id }) => id);
            claimedQuestIds.forEach(claimId => {
                queryClient.invalidateQueries({
                    queryKey: reviewsKeys.events(subdomain, claimId),
                });
                queryClient.setQueryData(reviewsKeys.claim(subdomain, claimId), (prev) => prev
                    ? {
                        ...prev,
                        status: 'pending',
                    }
                    : undefined);
            });
            queryClient.setQueryData(reviewsKeys.pendingCount(subdomain), (prev) => {
                if (typeof prev !== 'undefined') {
                    return prev + ids.length;
                }
                return prev;
            });
        },
    });
};
