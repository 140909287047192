import { useQuery } from '@tanstack/react-query';
import { blockchainKeys } from '../blockchain.keys';
import { getTokenContractMetadata } from '../blockchain.service';
export const useTokenContractMetadata = ({ contract, network, }, options) => {
    return useQuery({
        ...options,
        queryKey: blockchainKeys.tokenContract(network, contract),
        queryFn: () => getTokenContractMetadata({ contract, network }),
        retry: false,
    });
};
