import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { partnershipKeys } from '../partnership.keys';
import { searchPartnershipCommunities } from '../partnership.service';
export const useSearchPartnershipCommunities = ({ subdomain = getSubdomain(), search, }) => {
    return useInfiniteQuery({
        queryKey: partnershipKeys.searchPartnershipCommunities(subdomain, search),
        queryFn: ({ pageParam }) => searchPartnershipCommunities(subdomain, search, pageParam),
        initialPageParam: 1,
        getNextPageParam: lastPage => (lastPage.hasNextPage ? lastPage.nextPage : undefined),
        placeholderData: keepPreviousData,
        initialData: {
            pages: [{ results: [], nextPage: 1, hasNextPage: true }],
            pageParams: [],
        },
        staleTime: 0,
        gcTime: 0,
    });
};
