import { useQueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';
/**
 * Logs out the user
 * @example
 * ```ts
 * const logout = useLogout();
 * return <Button onClick={logout}>Logout</Button>
 * ```
 */
export const useLogout = () => {
    const queryClient = useQueryClient();
    return () => {
        Cookies.remove('user_metadata', {
            domain: `.${window.location.hostname}`,
        });
        Cookies.remove('user_metadata', {
            domain: window.location.hostname,
        });
        queryClient.removeQueries();
    };
};
