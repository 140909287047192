import { useQuery } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { getZealyConnectStatus } from '../auth.service';
export const useZealyConnectionStatus = ({ subdomain = getSubdomain(), questId, ...options }) => {
    return useQuery({
        ...options,
        queryKey: ['zealy-connect', subdomain, questId],
        queryFn: () => getZealyConnectStatus({
            questId,
            subdomain,
        }),
    });
};
