import { useQuery } from '@tanstack/react-query';
import { leaderboardKeys } from '../leaderboard.keys';
import { getUpcomingAndRecentSprints } from '../leaderboard.service';
export const useUpcomingAndRecentSprints = () => {
    return useQuery({
        queryKey: leaderboardKeys.upcomingAndRecentSprints(),
        queryFn: getUpcomingAndRecentSprints,
        staleTime: 30000,
    });
};
