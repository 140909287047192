import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { questKeys } from '../../quests/quests.keys';
import { reorderModules } from '../modules.service';
/**
 * Hook for reordering modules
 * @example ```
 * const { mutate, isLoading } = useReorderModules();
 * ```
 */
export const useReorderModules = (subdomain = getSubdomain()) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ moduleIds }) => reorderModules({ subdomain, moduleIds }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: questKeys.board(subdomain) });
        },
    });
};
