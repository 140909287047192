import { useQuery } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { questKeys } from '../quests.keys';
import { getAdminQuestboardWithClaim } from '../quests.service';
export const useAdminQuestboardWithClaim = ({ subdomain = getSubdomain(), startDate, enabled = true, }) => {
    return useQuery({
        queryKey: questKeys.admin.withClaim(subdomain, startDate),
        queryFn: async () => getAdminQuestboardWithClaim(subdomain, startDate),
        enabled,
    });
};
