import { useQuery } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { usersKeys } from '../users.keys';
import { getCommunityMember, getUser, isUserConnected } from '../users.service';
/**
 * Will return the general user data and data relevant for community if
 * subdomain is defined or possible to extract from the location path
 * @param subdomain of community to get user data from
 * @returns User object with role set if subdomain is defined
 */
export const useAuthenticatedUser = (subdomain, initialData, enabled) => {
    const fetchData = async (subdomain) => {
        if (subdomain && subdomain !== 'root' && subdomain !== '_') {
            // Fetch community member data
            return getCommunityMember('me', subdomain);
        }
        else {
            // Fetch general user data
            return getUser('me');
        }
    };
    const communityDomain = subdomain ?? getSubdomain();
    return useQuery({
        queryKey: usersKeys.user('me', communityDomain),
        queryFn: () => fetchData(communityDomain),
        initialData,
        enabled: isUserConnected(),
    });
};
