import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { getMe } from '../../services/utils';
import { notificationsKeys } from '../notifications.keys';
import { patchNotifications } from '../notifications.service';
export const useUpdateNotificationStatus = (subdomain = getSubdomain(), userId = getMe() ?? 'me') => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (body) => patchNotifications({
            params: {
                subdomain,
                userId,
            },
            body,
        }),
        onSuccess: data => {
            queryClient.invalidateQueries({
                queryKey: notificationsKeys.notifications(subdomain, userId),
            });
        },
    });
};
