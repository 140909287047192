import { useMutation, useQueryClient } from '@tanstack/react-query';
import { questKeys } from '../quests.keys';
import { voteEvent } from '../quests.service';
export const useVoteEvent = (params) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (body) => voteEvent(params, body),
        onSuccess: (data, variables) => {
            const cacheKey = questKeys.voteFeed(params.subdomain, params.questId, params.rewardId);
            const cloneItem = (item) => item.id === data.votedClaimedQuestId ? { ...item, vote: variables.action } : item;
            const clonePage = (page) => ({
                ...page,
                items: page.items.map(cloneItem),
            });
            const setCacheData = (cachedData) => ({
                ...cachedData,
                pages: cachedData.pages.map(clonePage),
            });
            queryClient.setQueryData(cacheKey, setCacheData);
        },
    });
};
