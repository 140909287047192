import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { reviewsKeys } from '../reviews.keys';
import { getReview } from '../reviews.service';
export const useClaim = (id, subdomain = getSubdomain(), initialData) => {
    const queryClient = useQueryClient();
    return useQuery({
        queryKey: reviewsKeys.claim(subdomain, id),
        queryFn: async () => {
            const review = await getReview({
                params: { subdomain, id },
            });
            /**
             * This will make sure that if the user goes through the list of reviews and opens a
             * review another person has already reviewed, it will update the status in the list.
             */
            const caches = queryClient.getQueriesData({
                queryKey: reviewsKeys.list(subdomain),
            });
            caches.forEach(([queryKey, cache]) => {
                if (typeof queryKey[2] === 'object' && queryKey[2] && 'sortBy' in queryKey[2] && cache) {
                    const updatedPages = cache.pages.map(page => ({
                        ...page,
                        items: page.items.map(item => (item.id === review.id ? review : item)),
                    }));
                    queryClient.setQueryData(queryKey, { ...cache, pages: updatedPages });
                }
            });
            return review;
        },
        staleTime: 1000,
    });
};
