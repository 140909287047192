import { useQuery } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { reviewsKeys } from '../reviews.keys';
import { getReviewAlerts } from '../reviews.service';
export const useReviewAlerts = (id, subdomain = getSubdomain(), enabled = true) => {
    return useQuery({
        queryKey: reviewsKeys.alerts(subdomain, id),
        queryFn: async () => getReviewAlerts({
            params: { subdomain, id },
        }),
        enabled: !!id && enabled,
    });
};
