import { initContract } from '@ts-rest/core';

const c: ReturnType<typeof initContract> = initContract();

export const router: typeof c.router = c.router;
export const query = c.query;
export const mutation = c.mutation;
export const otherResponse = c.otherResponse;
export const type = c.type;
export const noBody = c.noBody;
