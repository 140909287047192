import { useQuery } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { reviewsKeys } from '../reviews.keys';
import { getPendingReviews } from '../reviews.service';
/**
 * Gets the number of pending reviews for a given community
 * @param options
 * @returns
 */
export function usePendingReviewsCount(options) {
    const subdomain = getSubdomain();
    return useQuery({
        queryKey: reviewsKeys.pendingCount(subdomain),
        queryFn: () => getPendingReviews(subdomain),
        ...(options ? options : {}),
        staleTime: 100000,
    });
}
