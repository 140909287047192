import { useMutation, useQueryClient } from '@tanstack/react-query';
import { usersKeys } from '../users.keys';
import { disconnectUserWallet } from '../users.service';
export const useDisconnectUserWallet = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: disconnectUserWallet,
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: usersKeys.user('me') });
        },
    });
};
