import { useQuery } from '@tanstack/react-query';
import { questKeys } from '../quests.keys';
import { listRecommendedQuests } from '../quests.service';
export const useRecommendedQuests = () => {
    return useQuery({
        queryKey: questKeys.recommendedQuests(),
        queryFn: listRecommendedQuests,
        staleTime: 30000,
    });
};
