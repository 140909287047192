import { useMutation } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { updateSubscription } from '../subscription.service';
/**
 * Mutation hook for updating a community's subscription
 */
export function useUpdateSubscription() {
    const domain = getSubdomain();
    return useMutation({
        mutationFn: ({ metadata }) => updateSubscription(domain, {
            metadata,
        }),
    });
}
