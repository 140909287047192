import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { crmKeys } from '../crm.keys';
import { updateView } from '../crm.service';
export const useUpdateCRMView = ({ subdomain = getSubdomain() } = {}) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (columns) => updateView({ path: { subdomain }, body: { columns } }),
        onSuccess: (data, columns) => {
            queryClient.setQueryData(crmKeys.view(subdomain), columns);
        },
    });
};
