import { useMutation, useQueryClient } from '@tanstack/react-query';
import { usersKeys } from '../users.keys';
import { createUser } from '../users.service';
export const useCreateUser = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: createUser,
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: usersKeys.user('me') });
        },
    });
};
