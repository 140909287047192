import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { crmKeys } from '../crm.keys';
import { crmAction } from '../crm.service';
export const useCRMAction = ({ subdomain = getSubdomain() } = {}) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (body) => crmAction({ path: { subdomain }, body }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: crmKeys.crm(subdomain) });
        },
    });
};
