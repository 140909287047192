import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { leaderboardKeys } from '../leaderboard.keys';
import { deleteSprint } from '../leaderboard.service';
export const useDeleteSprint = (sprintId, subdomain = getSubdomain()) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: () => deleteSprint(subdomain, sprintId),
        onSuccess: data => {
            queryClient.setQueryData(leaderboardKeys.sprints(subdomain), data);
        },
    });
};
