import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { getSubdomain, isValidSubdomain } from '@zealy/utils';
import { isUserConnected } from '../../users';
import { reviewsKeys } from '../reviews.keys';
import { listReviews } from '../reviews.service';
export const useListReviews = (subdomain = getSubdomain(), 
// We need to override the sortBy type because the API expects a string
options, initialData, enabled = true) => {
    const queryClient = useQueryClient();
    return useInfiniteQuery({
        queryKey: reviewsKeys.list(subdomain, options),
        queryFn: async ({ pageParam }) => {
            const reviews = await listReviews({
                params: {
                    subdomain,
                },
                query: {
                    ...(!!options &&
                        Object.keys(options ?? {}).reduce((acc, key) => ({
                            ...acc,
                            [key]: options[key],
                        }), {})),
                    cursor: pageParam?.cursor ?? undefined,
                    lastSeenStatus: pageParam?.lastSeenStatus,
                },
            });
            reviews.items.forEach(review => {
                if (review) {
                    const cacheKey = reviewsKeys.claim(subdomain, review.id);
                    queryClient.setQueryData(cacheKey, review);
                }
            });
            return reviews;
        },
        getNextPageParam: lastPage => lastPage?.nextCursor
            ? {
                cursor: lastPage?.nextCursor,
                lastSeenStatus: lastPage?.items[lastPage.items.length - 1].status,
            }
            : undefined,
        initialData: initialData
            ? {
                pages: [initialData],
                pageParams: [
                    {
                        cursor: initialData.nextCursor,
                        lastSeenStatus: initialData?.items[initialData.items.length - 1].status,
                    },
                ],
            }
            : undefined,
        initialPageParam: {
            cursor: undefined,
            lastSeenStatus: undefined,
        },
        enabled: isUserConnected() && isValidSubdomain(subdomain) && enabled,
    });
};
