import { useQuery } from '@tanstack/react-query';
import { getSubdomain, isAuthenticated } from '@zealy/utils';
import { communityKeys } from '../communities.keys';
import { getTwitterOAuthURI, verifyCommunityExternalAccounts } from '../communities.service';
/**
 * Queries a community's Twitter OAuth URL by it's subdomain
 * @param subdomain
 */
export function useCommunityTwitterOAuthURI(subdomain) {
    const communityOAuthQuery = useQuery({
        queryKey: communityKeys.twitterOAuthURL(subdomain),
        queryFn: () => getTwitterOAuthURI(subdomain),
        enabled: false,
    });
    return communityOAuthQuery;
}
/**
 * Verifies a community's Twitter authentication status
 * @param subdomain
 */
export function useCommunityExternalAccounts(subdomain = getSubdomain()) {
    return useQuery({
        queryKey: communityKeys.communityExternalAccounts(subdomain),
        queryFn: () => verifyCommunityExternalAccounts(subdomain),
        enabled: isAuthenticated() && !!subdomain && subdomain !== 'root',
    });
}
