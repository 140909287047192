import { useQuery } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { communityKeys } from '../communities.keys';
import { getCommunityDiscordRoles } from '../communities.service';
export const useCommunityDiscordRoles = (subdomain = getSubdomain(), options) => {
    return useQuery({
        queryKey: communityKeys.roles(subdomain),
        queryFn: () => getCommunityDiscordRoles(subdomain),
        enabled: !!subdomain && subdomain !== 'root',
        retry: false,
        ...options,
    });
};
