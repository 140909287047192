import { useMutation } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { createCheckoutSession } from '../subscription.service';
/**
 * Mutation hook for creating a checkout session
 */
export function useCreateCheckoutSession() {
    const domain = getSubdomain();
    return useMutation({
        mutationFn: ({ usagePriceId, basePriceId }) => createCheckoutSession(domain, usagePriceId, basePriceId),
    });
}
