import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import { communityKeys } from '../communities.keys';
import { getExploreCommunities } from '../communities.service';
export const useInfiniteCommunities = (filter, limit = 30, initialData) => {
    return useInfiniteQuery({
        queryKey: communityKeys.explore(filter, limit),
        queryFn: ({ pageParam }) => {
            return getExploreCommunities({
                ...filter,
                pageParam,
                limit,
            });
        },
        getNextPageParam: lastPage => {
            if (lastPage.hasNextPage) {
                return lastPage.nextPage;
            }
            return undefined;
        },
        retry: 3,
        placeholderData: keepPreviousData,
        initialPageParam: 0,
        initialData: initialData
            ? {
                pages: [initialData],
                pageParams: [0],
            }
            : undefined,
    });
};
