import { useQuery } from '@tanstack/react-query';
import { blockchainKeys } from '../blockchain.keys';
import { getNFTCollectionMetadataBatch } from '../blockchain.service';
export const useNFTCollectionMetadataBatch = (contractAddress) => {
    return useQuery({
        queryKey: blockchainKeys.nftCollectionBatch('polygon', contractAddress),
        queryFn: () => getNFTCollectionMetadataBatch(contractAddress),
        enabled: !!contractAddress.length,
    });
};
