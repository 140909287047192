import { useQuery } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { analyticsKeys } from '../analytics.keys';
import { getTopQuests } from '../analytics.service';
import { FiveMinutes, InitialRetryDelay } from '../constants';
export const useTopQuests = ({ subdomain = getSubdomain(), startDate, endDate, ...options }) => {
    const q = useQuery({
        refetchInterval: query => (!query.state.data?.isComputed ? InitialRetryDelay : false),
        staleTime: FiveMinutes,
        ...options,
        queryKey: analyticsKeys.topQuests(subdomain, startDate, endDate),
        queryFn: () => getTopQuests(subdomain, startDate, endDate),
    });
    return { ...q, isPending: q.isPending || !q.data?.isComputed };
};
