import { api } from '../services/api';
export const getModule = (subdomain, moduleId, options) => api.get(`/communities/${subdomain}/modules/${moduleId}`, options);
export const getModules = (subdomain, options) => api.get(`/communities/${subdomain}/modules`, options);
export const createModule = ({ subdomain, data, }) => api.post(`/communities/${subdomain}/modules`, {
    body: JSON.stringify(data),
});
export const updateModule = ({ subdomain, moduleId, data, }) => api.patch(`/communities/${subdomain}/modules/${moduleId}`, {
    body: JSON.stringify(data),
});
export const deleteModule = ({ subdomain, moduleId }) => api.delete(`/communities/${subdomain}/modules/${moduleId}`);
export const duplicateModule = ({ subdomain, moduleId }) => api.post(`/communities/${subdomain}/modules/${moduleId}/duplicate`);
export const reorderModules = ({ subdomain, moduleIds, }) => api.patch(`/communities/${subdomain}/modules/reorder`, {
    body: {
        moduleIds,
    },
});
