import { useQuery } from '@tanstack/react-query';
import { communityKeys } from '../communities.keys';
import { getCommunities } from '../communities.service';
/**
 * Queries communities by category
 * @param category The category to query @default 'all'
 * @param page The page number to query communities @default 0
 * @param limit The number of communities to return @default 12
 * @example ```
 * const { data, isLoading } = useCommunities('nft');
 * ```
 */
export const useCommunities = (category = 'all', page = 0, limit = 12, options = {}) => useQuery({
    queryKey: communityKeys.category(category, limit, page),
    queryFn: () => getCommunities(category, limit, page),
    ...options,
});
