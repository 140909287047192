import { useQuery } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { getInvitation } from '../communities.service';
export const useInvitation = ({ id, subdomain = getSubdomain(), }) => {
    return useQuery({
        queryKey: ['invitation', id],
        queryFn: () => getInvitation({ id: id ?? '', subdomain }),
        enabled: !!id,
    });
};
