export const partnershipKeys = {
    invalidateSearchPartnershipCommunities: (subdomain) => [
        'search-partnership-communities',
        subdomain,
    ],
    searchPartnershipCommunities: (subdomain, criteria) => [
        'search-partnership-communities',
        subdomain,
        criteria,
    ],
    genericKey: (subdomain) => ['list-partnerships', subdomain],
    listPartnerships: (subdomain, status, position) => position
        ? ['list-partnerships', subdomain, status, position]
        : ['list-partnerships', subdomain, status],
};
