import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { resetLeaderboard } from '../communities.service';
export const useResetLeaderboard = (subdomain = getSubdomain()) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: () => resetLeaderboard(subdomain),
        onSuccess: () => {
            queryClient.removeQueries();
        },
    });
};
