import { useQuery } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { reviewsKeys } from '../reviews.keys';
import { getUserStats } from '../reviews.service';
export const useUserStats = (userId, subdomain = getSubdomain()) => {
    return useQuery({
        queryKey: reviewsKeys.userStats(subdomain, userId),
        queryFn: async () => getUserStats({
            params: { subdomain, id: userId },
        }),
    });
};
