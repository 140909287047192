import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { questKeys } from '../quests.keys';
import { getQuestboardModule } from '../quests.service';
export const useModule = (moduleId, filters) => {
    const queryClient = useQueryClient();
    const subdomain = getSubdomain();
    return useQuery({
        queryKey: questKeys.module(subdomain, moduleId, filters),
        queryFn: () => getQuestboardModule(subdomain, moduleId, filters),
        initialData: () => {
            const qbCache = queryClient.getQueryData(questKeys.board(subdomain, filters));
            return qbCache?.find(mod => mod.id === moduleId);
        },
        placeholderData: keepPreviousData,
    });
};
