import { useMutation, useQueryClient } from '@tanstack/react-query';
import { questKeys } from '../quests.keys';
import { updateTokenRewardTransaction } from '../quests.service';
/**
 * Hook for updating a token reward
 * @example ```
 * const { mutate, isPending } = useUpdateTokenRewardTransaction();
 * ```
 */
export const useUpdateTokenRewardTransaction = ({ id, subdomain, questId, }) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (data) => updateTokenRewardTransaction({ id, subdomain, data }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: questKeys.questRewards(subdomain, questId) });
        },
    });
};
