import { useInfiniteQuery } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { questKeys } from '../quests.keys';
import { getQuestVoteFeed } from '../quests.service';
/**
 * Queries vote feed for a quest with a vote reward
 * @param questId The ID of the Quest to query
 * @param subdomain The subdomain of the community to query, @default subdomain will be inferred from the URL
 * @param rewardId The ID of the reward to query
 * @param initialData The initial data to return
 * @param enabled If the query should be enabled
 */
export const useQuestVoteFeed = ({ questId, subdomain = getSubdomain(), rewardId, initialData, enabled = true, }) => {
    return useInfiniteQuery({
        queryKey: questKeys.voteFeed(subdomain, questId ?? '', rewardId ?? ''),
        queryFn: async ({ pageParam }) => getQuestVoteFeed({
            questId: questId ?? '',
            rewardId: rewardId,
            subdomain: subdomain,
            pagination: { limit: 1000, cursor: pageParam?.cursor ?? null },
        }),
        enabled: !!subdomain && subdomain !== 'root' && !!questId && !!rewardId && enabled,
        getNextPageParam: lastPage => lastPage?.nextCursor ? { cursor: lastPage.nextCursor } : undefined,
        initialData: initialData
            ? {
                pages: [initialData],
                pageParams: [{ cursor: initialData.nextCursor }],
            }
            : undefined,
        initialPageParam: { cursor: null },
    });
};
