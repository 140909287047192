import { useMutation } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { generateRedirectUrlForApiQuestTask } from '../auth.service';
export const useRedirectUrlApiQuestTask = ({ subdomain = getSubdomain(), questId, taskId, }) => {
    return useMutation({
        mutationFn: async () => {
            return generateRedirectUrlForApiQuestTask({
                subdomain,
                questId,
                taskId,
            });
        },
    });
};
