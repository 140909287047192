import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { reviewsKeys } from '../reviews.keys';
import { getRecentReviewComments } from '../reviews.service';
export const useReviewComments = (questId) => {
    const subdomain = getSubdomain();
    return useQuery({
        queryKey: reviewsKeys.comments(subdomain, questId),
        queryFn: () => getRecentReviewComments(subdomain, questId),
        placeholderData: keepPreviousData,
        enabled: !!questId,
    });
};
