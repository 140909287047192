import { apiV2 } from '../services';
export const impersonateUser = async (email) => {
    const { status, body } = await apiV2.auth.impersonate({
        body: {
            email,
        },
    });
    if (status !== 200) {
        throw new Error('Failed to impersonate');
    }
    return body;
};
export const setRole = async (params, role) => {
    const { status, body } = await apiV2.communityMember.setRole({
        params,
        body: {
            role,
        },
    });
    if (status !== 200) {
        throw new Error('Failed to impersonate');
    }
    return body;
};
