import { useQuery } from '@tanstack/react-query';
import { blockchainKeys } from '../blockchain.keys';
import { getNFTCollectionMetadata } from '../blockchain.service';
export const useNFTCollectionMetadata = ({ contract, network, }, options) => {
    return useQuery({
        ...options,
        queryKey: blockchainKeys.nftCollection(network, contract),
        queryFn: () => getNFTCollectionMetadata({ contract, network }),
        retry: false,
    });
};
